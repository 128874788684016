import {AfterViewInit, ChangeDetectorRef, Component, inject} from '@angular/core'


@Component({
  selector: 'cdk-loading',
  templateUrl: './cdk-loading.component.html',
  styleUrl: './cdk-loading.component.scss'
})
export class CdkLoadingComponent implements AfterViewInit {
  changeDetectorReference = inject(ChangeDetectorRef)

  ngAfterViewInit(): void {
    this.changeDetectorReference.detectChanges()
  }
}
