import {Component, inject, OnInit} from '@angular/core'
import {Select} from "@ngxs/store"
import AppState from "./store/app.state"
import {Observable} from "rxjs"
import AppFacade from "./store/facade/app.facade"

@Component({
  selector: 'disvel-root',
  template: `
    <cdk-loading *ngIf="showLoading$ | async"/>
    <div [class.d-none]="hideContent$ | async">
      <router-outlet/>
    </div>`,
})
export class AppComponent implements OnInit {
  @Select(AppState.getLoadingGlobal) showLoading$?: Observable<boolean>
  @Select(AppState.getHideContent) hideContent$?: Observable<boolean>
  private readonly appFacade: AppFacade = inject(AppFacade)

  ngOnInit() {
    this.appFacade.showLoading()
    this.appFacade.hideContent()
  }
}
